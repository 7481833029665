import Card from "components/card";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, SettingControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notify, notifyError } from "toast";
import Loading from "views/core/components/Loading";

const PhysicianSettings = () => {
  const initialValues = useRef({
    appointmentBufferTime: 0,
    appointmentMinimumNoticeTime: 0,
    dailyAppointmentLimit: 0,
    appointmentCalendarLimit: 0,
  })
  const [appointmentBufferTime, setAppointmentBufferTime] = useState<{ value: number, id: string }>({ value: 0, id: "" });
  const [appointmentMinimumNoticeTime, setAppointmentMinimumNoticeTime] = useState<{ value: number, id: string }>({ value: 0, id: "" });
  const [dailyAppointmentLimit, setDailyAppointmentLimit] = useState<{ value: number, id: string }>({ value: 0, id: "" });
  const [appointmentCalendarLimit, setAppointmentCalendarLimit] = useState<{ value: number, id: string }>({ value: 0, id: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuthStore();
  const api = new SettingControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))
  const settingListRequest = async (token?: string) => {
    return await api.getSettingList({
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`,
      },
    });
  };

  const updateSettingRequest = async (settingId: string, value: number, token?: string) => {
    return api.updateSetting(
      settingId,
      {
        value: value.toString(),
      },
      {
        headers: {
          Authorization: `Bearer ${token ?? auth.token}`,
        },
      }
    );
  }

  const updateSetting = async (settingId: string, value: number): Promise<boolean> => {
    setLoading(true);
    let response = await updateSettingRequest(settingId, value);
    if (response.data.statusCode === 200) {
      setLoading(false);
      notify("Updated successfully");
      return true;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await updateSettingRequest(settingId, value, token);
    if (response.data.statusCode !== 200) {
      notifyError("Something went wrong");
      setLoading(false);
      return false;
    }
    setLoading(false);
    notify("Updated successfully");
    return true;
  }

  const handleInputBlur = async (
    settingId: string,
    currentValue: number,
    settingKey: keyof typeof initialValues.current
  ) => {
    if (currentValue === initialValues.current[settingKey]) return;

    let response = await updateSetting(settingId, currentValue);
    if (response) {
      initialValues.current[settingKey] = currentValue;
    }
  }

  const listSettings = async () => {
    setLoading(true);
    let response = await settingListRequest();
    if (response.data.statusCode === 200) {
      const data = response.data.data as any[];
      parseSettings(data);
      setLoading(false);
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await settingListRequest(token);
    if (response.data.statusCode !== 200) {
      notifyError("Something went wrong");
      setLoading(false);
      return;
    }
    const data = response.data.data as any[];
    parseSettings(data);
    setLoading(false);
  }

  const parseSettings = (settings: any[]) => {
    settings.forEach((setting) => {
      switch (setting.key) {
        case "appointment_buffer_time":
          setAppointmentBufferTime({ value: setting.value, id: setting.settingId });
          initialValues.current.appointmentBufferTime = parseInt(setting.value);
          break;
        case "appointment_minimum_notice_time":
          setAppointmentMinimumNoticeTime({ value: setting.value, id: setting.settingId });
          initialValues.current.appointmentMinimumNoticeTime = parseInt(setting.value);
          break;
        case "appointment_daily_limit":
          setDailyAppointmentLimit({ value: setting.value, id: setting.settingId });
          initialValues.current.dailyAppointmentLimit = parseInt(setting.value);
          break;
        case "appointment_calendar_limit":
          setAppointmentCalendarLimit({ value: setting.value, id: setting.settingId });
          initialValues.current.appointmentCalendarLimit = parseInt(setting.value);
          break;
        default:
          console.log("Unknown setting key", setting.key)
      }
    })
  }

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<{ value: number, id: string }>>, value: number) => {
    setter(prev => ({ ...prev, value }))
  }

  useEffect(() => {
    listSettings().then(() => console.log("Listed settings successfully"));
  }, [])

  if (loading) return <Loading />
  return (
    <Card extra="!p-[20px]">
      <div className="w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Physician Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Manage your appointment preferences
        </p>

        <div className="mt-6 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="appointmentBufferTime" className="text-sm text-navy-700 dark:text-white font-bold">
              Appointment Buffer Time (minutes)
            </label>
            <input
              id="appointmentBufferTime"
              type="number"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              placeholder="Enter buffer time in minutes"
              min={0}
              value={appointmentBufferTime.value ?? 0}
              onChange={(e) => handleInputChange(setAppointmentBufferTime, Number(e.target.value))}
              onBlur={() => handleInputBlur(appointmentBufferTime.id, appointmentBufferTime.value, "appointmentBufferTime")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="appointmentMinimumNoticeTime" className="text-sm text-navy-700 dark:text-white font-bold">
              Appointment Minimum Notice Time (hours)
            </label>
            <input
              id="appointmentMinimumNoticeTime"
              type="number"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              placeholder="Enter minimum notice time in hours"
              min={0}
              value={appointmentMinimumNoticeTime.value ?? 0}
              onChange={(e) => handleInputChange(setAppointmentMinimumNoticeTime, Number(e.target.value))}
              onBlur={() => handleInputBlur(appointmentMinimumNoticeTime.id, appointmentMinimumNoticeTime.value, "appointmentMinimumNoticeTime")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="dailyAppointmentLimit" className="text-sm text-navy-700 dark:text-white font-bold">
              Daily Appointment Limit
            </label>
            <input
              id="dailyAppointmentLimit"
              type="number"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              placeholder="Enter daily appointment limit"
              min={1}
              value={dailyAppointmentLimit.value ?? 0}
              onChange={(e) => handleInputChange(setDailyAppointmentLimit, Number(e.target.value))}
              onBlur={() => handleInputBlur(dailyAppointmentLimit.id, dailyAppointmentLimit.value, "dailyAppointmentLimit")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="appointmentCalendarLimit" className="text-sm text-navy-700 dark:text-white font-bold">
              Appointment Calendar Limit (days)
            </label>
            <input
              id="appointmentCalendarLimit"
              type="number"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              placeholder="Enter calendar limit in days"
              min={7}
              value={appointmentCalendarLimit.value ?? 0}
              onChange={(e) => handleInputChange(setAppointmentCalendarLimit, Number(e.target.value))}
              onBlur={() => handleInputBlur(appointmentCalendarLimit.id, appointmentCalendarLimit.value, "appointmentCalendarLimit")}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PhysicianSettings;
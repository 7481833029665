import TwoFactor from "components/settings/TwoFactor";
import Profile from "./components/Profile";
import Password from "components/settings/Password";
import NewsLetter from "components/settings/NewsLetter";
import Session from "components/settings/Session";
import Delete from "components/settings/Delete";

const Setting = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Profile />
        <Password />
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor />
        <NewsLetter />
        <Session />
        <Delete />
      </div>
    </div>
  );
};

export default Setting;

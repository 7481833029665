import { useNavigate } from "react-router-dom";

export default function SpecializationCard(props: { specialization: string, icon: React.ReactNode }) {
  const { specialization, icon } = props;
  const handleClick = () => {
    navigate("/patient/choose-physician", { state: { specialization } })
  }
  const navigate = useNavigate()
  return <div className="bg-heuplus-500 xsm:px-3 xsm:py-2 lg:px-3 lg:py-2 rounded-xl text-heuplus-50 flex xsm:flex-row md:flex-col lg:flex-col sm:flex-col xlg:flex-col xsm:gap-2 lg:gap-4 cursor-pointer xsm:items-center sm:items-start lg:items-start md:items-start xlg:items-start justify-between">
    {icon}
    <button onClick={handleClick} className="text-xs md:text-sm sm:text-sm xl:text-sm lg:text-xs">{specialization}</button>
  </div>;
}
import Card from "components/card";
import SwitchField from "components/fields/SwitchField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, UserControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notifyError } from "toast";

const NewsLetter = () => {
  const auth = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [subscribed, setSubscribed] = useState(auth.user?.newsletterSubscribed ?? false);

  useEffect(() => {
    auth.updateUser({ newsletterSubscribed: subscribed })
    const e = document.getElementById("newsletter") as HTMLInputElement;
    if (e) {
      e.checked = subscribed;
    }
  }, [subscribed]);

  const api = new UserControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }))

  const toggleSubscribedRequest = async (token?: string) => {
    return api.toggleNewsletterSubscribed({
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    })
  }

  const handleChange = async () => {
    let response = await toggleSubscribedRequest();
    if (response.data?.statusCode === 200) {
      setSubscribed(!subscribed);
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await toggleSubscribedRequest(token);
    if (response.data?.statusCode !== 200) {
      notifyError("Something went wrong, please try again later.");
      return;
    }
    setSubscribed(!subscribed);
  }
  return (
    <Card extra={"w-full px-[30px] py-[30px] pb-10"}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Newsletter
        </h4>
      </div>
      <div>
        <SwitchField
          id="newsletter"
          label="Subscribe to Weekly Newsletter"
          desc="Get notified about articles, discounts and new products."
          mt="mt-[45px]"
          mb="mb-[25px]"
          onChange={handleChange}
        />
      </div>
    </Card>
  );
};

export default NewsLetter;

import Card from "components/card";
import LanguageSelector from "components/language/LanguageSelector";
import { useState } from "react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

const ThemeAndLanguage = () => {
  const [darkmode, setDarkmode] = useState(document.body.classList.contains("dark"));
  return (
    <Card extra={"w-full px-[30px] py-[30px] pb-10"}>
      <div className="flex items-center justify-between mb-5">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Theme and Language Preferences
        </h4>
      </div>
      <div className="flex items-centere justify-evenly">
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            setDarkmode(!darkmode);
            document.body.classList.toggle("dark", !darkmode);
          }}
        >
          {darkmode ? <RiSunFill className="h-4 w-4" /> : <RiMoonFill className="h-4 w-4" />}
        </div>

        <LanguageSelector />
      </div>
    </Card>
  )
}

export default ThemeAndLanguage;
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Configuration, ProcedureControllerApi } from 'rest-client-sdk';
import { useAuthStore } from 'store/useAuthStore';
import { notifyError } from 'toast';

export interface ProcedureModalProps {
  isOpen: boolean;
  onClose: () => void;
  procedure: {
    id?: string;
    name?: string;
    price?: number;
    description?: string;
  } | null;
  fetchProcedures: () => Promise<void>;
}

const ProcedureModal: React.FC<ProcedureModalProps> = ({ isOpen, onClose, procedure, fetchProcedures }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [id, setId] = useState("")

  useEffect(() => {
    if (procedure) {
      setName(procedure.name || "");
      setPrice(procedure.price || 0);
      setDescription(procedure.description || "");
      setId(procedure.id || "");
    }
  }, [procedure])


  const auth = useAuthStore();
  const procedureApi = new ProcedureControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }))

  const createProcedureRequest = async (token?: string) => {
    return procedureApi.createProcedure({
      name: name,
      price: price,
      description: description,
    }, {
      headers: {
        Authorization: `Bearer ${token || auth.token}`
      }
    })
  }

  const updateProcedureRequest = async (token?: string) => {
    return procedureApi.updateProcedure(id, {
      name: name,
      price: price,
      description: description,
    }, {
      headers: {
        Authorization: `Bearer ${token || auth.token}`
      }
    })
  }

  const deleteProcedureRequest = async (token?: string) => {
    return procedureApi.deleteProcedure(id, {
      headers: {
        Authorization: `Bearer ${token || auth.token}`
      }
    })
  }

  const handleSave = async () => {
    console.log("Got in save")
    console.log("Id is", id);
    if (id) {
      console.log("Got in update with Id", id)
      let response = await updateProcedureRequest();
      if (response.data.statusCode === 200) {
        onClose();
        await fetchProcedures();
        return;
      }
      const token = await auth.refreshTokenAction(t, navigate);
      response = await updateProcedureRequest(token);
      if (response.data.statusCode !== 200) {
        onClose();
        notifyError("Something went wrong");
        return;
      }
      onClose();
      await fetchProcedures();
      return;
    }
    console.log("Going into create");
    let response = await createProcedureRequest();
    if (response.data.statusCode === 201) {
      onClose();
      await fetchProcedures();
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await createProcedureRequest(token);
    if (response.data.statusCode !== 201) {
      onClose();
      notifyError("Something went wrong");
      return;
    }
    onClose();
    await fetchProcedures();
  };

  const handleDelete = async () => {
    let response = await deleteProcedureRequest();
    if (response.data.statusCode === 200) {
      onClose();
      await fetchProcedures();
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await deleteProcedureRequest(token);
    if (response.data.statusCode !== 200) {
      onClose();
      notifyError("Something went wrong");
      return;
    }
    onClose();
    await fetchProcedures();
  };

  if (!isOpen) return null;
  console.log("Showing with ID", id)
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4">{id ? 'Edit Procedure' : 'Add New Procedure'}</h2>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-heuplus-500 focus:ring-heuplus-500 p-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-heuplus-500 focus:ring-heuplus-500 p-2 appearance-none"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-heuplus-500 focus:ring-heuplus-500 p-2"
              rows={3}
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          {id && (
            <button
              onClick={handleDelete}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
            >
              Delete
            </button>
          )}
          <button
            onClick={handleSave}
            className="bg-heuplus-500 text-white px-4 py-2 rounded-lg hover:bg-heuplus-600"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcedureModal;
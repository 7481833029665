import { useNavigate } from "react-router-dom";

export default function Consultation() {
  const navigate = useNavigate();
  return <div className="flex bg-heuplus-600 py-5 px-8 rounded-xl justify-between">
    <div>
      <p className="text-lightPrimary text-lg">Consult an expert from anywhere you want!</p>
      <p className="text-heuplus-300 text-opacity-90">Better treatment with better cost and less time.</p>
    </div>
    <button onClick={() => navigate("/patient/choose-physician")} className="bg-heuplus-500 p-2 rounded-xl text-heuplus-50">Get Consulting</button>
  </div>;
}

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import {ChakraProvider, defaultSystem} from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
      <ChakraProvider value={defaultSystem}>
          <App />
      </ChakraProvider>
  </BrowserRouter>
);

import React from "react";
import { useAuthStore } from "store/useAuthStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePatientSelfStore } from "store/usePatientSelfStore";
import Loading from "views/core/components/Loading";
import { notifyError } from "toast";
import Consultation from "./components/Consultation";
import Specialization from "./components/Specialization";
import TopPhysicians from "./components/TopPhysicians";
import TotalAppointments from "./components/TotalAppointments";

const Dashboard = () => {
  const auth = useAuthStore();
  const { t } = useTranslation()
  const navigate = useNavigate()
  const patientSelfStore = usePatientSelfStore();

  const fetchPatientData = async () => {
    const details = await patientSelfStore.getPatientDetails(auth.token);
    if (patientSelfStore.getError()) {
      patientSelfStore.setError(null);
      const token = await auth.refreshTokenAction(t, navigate);
      const details = await patientSelfStore.getPatientDetails(token);
      if (patientSelfStore.getError()) {
        console.log("error: ", patientSelfStore.getError())
        notifyError(patientSelfStore.getError());
        navigate("/auth/sign-in")
        return;
      }
      patientSelfStore.setLoading(false);
      auth.updateUser(details);
      return;
    }
    auth.updateUser(details);
    patientSelfStore.setLoading(false);
  };

  React.useEffect(() => {
    fetchPatientData().then(() => console.log("Patient data fetched"));
  }, []);

  return patientSelfStore.getLoading() ? <Loading /> : (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <Consultation />
        </div>
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6 mt-3">
          <Specialization />
        </div>
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6 mt-3">
          <TopPhysicians />
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section */}
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <TotalAppointments />
      </div>
    </div>
  );
};

export default Dashboard;

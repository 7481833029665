import { useEffect, useState } from "react";
import { Configuration, PhysicianControllerApi } from "rest-client-sdk";
import { notifyError } from "toast";
import PhysicianCard from "./PhysicianCard";
import { useAuthStore } from "store/useAuthStore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopPhysicians = () => {
  const auth = useAuthStore();
  const physicianApi = new PhysicianControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [physicians, setPhysicians] = useState([]);
  const fetchPhysicians = async () => {
    try {
      let res = await physicianApi.getPhysicianList(
        {
          page: 0,
          size: 4,
        },
        {},
        { headers: { Authorization: `Bearer ${auth.token}` } }
      )
      if (res.data.statusCode === 200) {
        const data = (res.data.data as { content: any[] }).content;
        setPhysicians(data || []);
        return;
      }
      const token = await auth.refreshTokenAction(t, navigate);
      res = await physicianApi.getPhysicianList(
        {
          page: 0,
          size: 4,
        },
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      if (res.data.statusCode !== 200) {
        notifyError("Something went wrong");
        return;
      }
      const data = (res.data.data as { content: any[] }).content;
      setPhysicians(data || []);
    } catch (error) {
      console.error("Error fetching physicians:", error);
    }
  }

  useEffect(() => {
    fetchPhysicians().then(() => { console.log("Successfully fetched the physicians") }).catch(e => console.log(e));
  }, [])
  return (
    <div className="bg-heuplus-100 rounded-xl p-8">
      <h2 className="text-2xl font-bold mb-3">Top Physicians</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-col-2 gap-4">
        {physicians.map(e => <PhysicianCard key={e.physicianId} id={e.physicianId} firstName={e.firstName} lastName={e.lastName} specialization={e.specialization} />)}
      </div>
    </div>
  )
}

export default TopPhysicians;
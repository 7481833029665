import Card from "components/card";
import Dropdown from "components/dropdown";
import { useState } from "react";

const Delete = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const intervals = [6, 12, 24, 36, 48, 60];
  return (
    <Card extra="!flex gap-4 py-6 px-[30px] justify-between items-center w-full">
      <div className="flex justify-between items-center gap-3 w-full">
        <div>
          <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">Delete this account
          </h3>
          <p className="text-base text-gray-600">
            Here you can permanently delete this account
          </p>
        </div>
        <button className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-4 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80">
          Delete
        </button>
      </div>
      <div className="flex justify-between items-center gap-3 w-full">
        <div>
          <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">Delete non-account data
          </h3>
          <p className="text-base text-gray-600">
            Here you can delete this account's non-account data
          </p>
        </div>
        <button className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-4 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80">
          Delete
        </button>
      </div>
      <div className="flex justify-between items-center gap-3 w-full">
        <div>
          <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">Download account data
          </h3>
          <p className="text-base text-gray-600">
            Here you can download this account's data
          </p>
        </div>
        <button className="linear flex cursor-pointer items-center justify-center rounded-xl border border-green-500 px-4 py-2 text-base font-medium text-green-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80">
          Download
        </button>
      </div>
      <div className="flex justify-between items-center gap-3 w-full">
        <div>
          <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">Data deletion interval
          </h3>
          <p className="text-base text-gray-600">
            Here you can choose data deletion interval
          </p>
        </div>
        <Dropdown
          button={
            <div className="flex items-center space-x-2 cursor-pointer border-blue-500"
              onClick={() => setIsDropdownOpen(true)}
            >
              <p className="font-bold">Please choose</p>
            </div>
          }
          children={
            isDropdownOpen && (
              <div className="flex flex-col p-2 bg-white dark:bg-navy-700 rounded-md shadow-md">
                {intervals.map((interval) => (
                  <button
                    key={interval}
                    className="flex items-center space-x-2 p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    <span>{interval} months</span>
                  </button>
                ))}
              </div>
            )
          }
          classNames="" />
      </div>
    </Card>
  );
};

export default Delete;

import { useState } from "react";
import { Plugin } from "../types/plugin";
import Card from "components/card";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppSettingControllerApi, Configuration, UpdateAppSettingRequest } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notify } from "toast";
import TwilioPlugin from "./TwilioPlugin";
import AwsSesPlugin from "./AwsSesPlugin";
import SmtpPlugin from "./SmtpPlugin";

const EmailPlugin = ({ plugin }: { plugin: Plugin }) => {
  const [selectedPlugin, setSelectedPlugin] = useState<string>(plugin.pluginName || "twilio");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuthStore();
  const api = new AppSettingControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))

  const [formData, setFormData] = useState({ ...plugin?.pluginConfig });

  const updateEmailPluginRequest = async (payload: UpdateAppSettingRequest, token?: string) => {
    return api.updateAppSetting(plugin.id, payload, {
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    })
  }

  const handleSave = async () => {
    const payload = {
      pluginName: selectedPlugin,
      pluginType: "E-MAIL",
      pluginConfig: JSON.stringify(formData)
    }

    let response = await updateEmailPluginRequest(payload);
    if (response.data.statusCode === 200) {
      notify(t("Email plugin updated successfully"));
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate);
    response = await updateEmailPluginRequest(payload, token);
    if (response.data.statusCode !== 200) {
      notify(t("Something went wrong"));
      return;
    }

    notify(t("Email plugin updated successfully"));
  }

  return (
    <Card className="w-full px-6 py-3">
      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select E-mail Provider
        </label>
        <select
          value={selectedPlugin}
          onChange={(e) => setSelectedPlugin(e.target.value)}
          className="w-full max-w-xs px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
        >
          <option value="twilio">Twilio</option>
          <option value="aws-ses">AWS SES</option>
          <option value="smtp">SMTP</option>
        </select>
      </div>
      {selectedPlugin === "twilio" && <TwilioPlugin formData={formData} setFormData={setFormData} />}
      {selectedPlugin === "aws-ses" && <AwsSesPlugin formData={formData} setFormData={setFormData} />}
      {selectedPlugin === "smtp" && <SmtpPlugin formData={formData} setFormData={setFormData} />}
      <div className="mt-8">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-brand-500 text-white rounded-lg hover:bg-brand-600 
                   transition-colors duration-150 ease-in-out focus:outline-none 
                   focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default EmailPlugin;
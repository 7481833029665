import Card from "components/card";

const AdminDashboard = () => {
  return (
    <Card extra="flex !flex-row w-full h-full mt-3 p-6">
      <h1>Admin Dashboard</h1>
    </Card>
  )
};

export default AdminDashboard;

import physicianImg from "assets/img/avatars/avatar9.png";
import { useNavigate } from "react-router-dom";

interface PhysicianCardProps {
  id: string;
  firstName: string;
  lastName: string;
  specialization: string;
}

const PhysicianCard: React.FC<PhysicianCardProps> = ({ id, firstName, lastName, specialization }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-heuplus-200 flex rounded-md">
      <img src={physicianImg} alt="physician" width={120} height={120} className="rounded-md" />
      <div className="p-3 flex flex-col gap-2">
        <p className="text-heuplus-500 text-xs sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md font-bold">Dr. {firstName} {lastName}</p>
        <p className="text-heuplus-500 opacity-70 text-xs sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md">{specialization}</p>
        <button onClick={() => navigate(`/patient/schedule-appointment/${id}`)} className="bg-heuplus-50 text-heuplus-700 rounded-md px-2 py-1">Consult Now</button>
      </div>
    </div>
  )
}

export default PhysicianCard;
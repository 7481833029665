import Card from "components/card";
import SessionBadge from "components/dataDisplay/SessionBadge";
import { useAuthStore } from "store/useAuthStore";

const Session = () => {
  const auth = useAuthStore();
  return (
    <Card extra={"w-full py-4 px-8"}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Session
        </h4>
      </div>
      {/* content */}
      <div>
        {auth.user?.sessions?.map((session: string, idx: number) =>
          <SessionBadge
            name={session}
            key={idx}
            detail=""
            status="enabled"
            py=" py-[27px]"
            border="border-b"
            bgTextColor=" bg-green-100 dark:bg-green-50 text-green-500"
          />)
        }
      </div>
    </Card>
  );
};

export default Session;

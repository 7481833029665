const GcpCloudStoragePlugin = (props: { formData: any, setFormData: (formData: any) => void }) => {
  const { formData, setFormData } = props;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 max-w-full">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Bucket Name
        </label>
        <input
          type="text"
          name="bucketName"
          value={formData.bucketName}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter bucket name"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Project ID
        </label>
        <input
          type="text"
          name="projectId"
          value={formData.projectId}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your project ID"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Secret Key
        </label>
        <input
          type="password"
          name="jsonKey"
          value={formData.jsonKey}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter secret key"
        />
      </div>
    </div>
  )
};

export default GcpCloudStoragePlugin;

import Card from "components/card";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppSettingControllerApi, Configuration } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notifyError } from "toast";
import { Plugin } from "./types/plugin";
import TopBar from "./components/TopBar";
import FilePlugin from "./components/FilePlugin";
import EmailPlugin from "./components/EmailPlugin";
import Loading from "views/core/components/Loading";

const AdminSettings = () => {
  const auth = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filePlugin, setFilePlugin] = useState<Plugin | null>(null);
  const [emailPlugin, setEmailPlugin] = useState<Plugin | null>(null);
  const [activeTab, setActiveTab] = useState<string>("file");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const api = new AppSettingControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }))

  const parseSettings = (settings: any[]) => {
    settings.forEach((setting) => {
      const pluginType = (setting as { pluginType: string }).pluginType;
      if (pluginType === "FILE") {
        const { id, pluginName, pluginConfig } = setting as { id: string, pluginName: string, pluginType: string, pluginConfig: string };
        if (pluginName === "minio") {
          const config = JSON.parse(pluginConfig ?? "") as any;
          setFilePlugin({ id, pluginName, pluginType, pluginConfig: config });
        } else if (pluginName === "aws-s3") {
          const config = JSON.parse(pluginConfig ?? "") as any;
          setFilePlugin({ id, pluginName, pluginType, pluginConfig: config });
        } else if (pluginName === "gcp-cs") {
          const config = JSON.parse(pluginConfig ?? "") as any;
          setFilePlugin({ id, pluginName, pluginType, pluginConfig: config });
        }
      } else if (pluginType === "E-MAIL") {
        const { id, pluginName, pluginConfig } = setting as { id: string, pluginName: string, pluginType: string, pluginConfig: string };
        if (pluginName === "twilio") {
          console.log("Got in twilio")
          const config = JSON.parse(pluginConfig ?? "") as any;
          setEmailPlugin({ id, pluginName, pluginType, pluginConfig: config });
        } else if (pluginName === "aws-ses") {
          console.log("Got in aws-ses")
          const config = JSON.parse(pluginConfig ?? "") as any;
          setEmailPlugin({ id, pluginName, pluginType, pluginConfig: config });
        } else if (pluginName === "smtp") {
          console.log("Got in smtp")
          const config = JSON.parse(pluginConfig ?? "") as any;
          setEmailPlugin({ id, pluginName, pluginType, pluginConfig: config });
        }
      }
    })
  }

  const fetchSettingsRequest = async (token?: string) => {
    return api.listAppSettings({
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    })
  }

  const fetchSettings = async () => {
    let response = await fetchSettingsRequest();
    if (response.data.statusCode === 200) {
      parseSettings(response.data.data as any[])
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate);
    response = await fetchSettingsRequest(token);
    if (response.data.statusCode !== 200) {
      notifyError("Something went wrong");
      return;
    }
    parseSettings(response.data.data as any[])
  }

  useEffect(() => {
    setIsLoading(true);
    fetchSettings()
      .then(
        () => {
          console.log("Successfully fetched settings")
          setIsLoading(false)
        })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [])

  return (
    isLoading ? <Loading /> :
      <Card extra="flex flex-colw-full h-full mt-3">
        <TopBar activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === "file" && <FilePlugin plugin={filePlugin} />}
        {activeTab === "email" && <EmailPlugin plugin={emailPlugin} />}
      </Card>
  )
};

export default AdminSettings;

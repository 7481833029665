import Card from "components/card";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, ProcedureControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notifyError } from "toast";
import ProcedureModal from "./components/ProcedureModal";
import { MdChevronLeft } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

const Procedures: React.FC = () => {
  const auth = useAuthStore();
  const [procedures, setProcedures] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState<object | null>(null);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  const handleNextPage = () => {
    if (page < maxPage - 1) {
      setPage(page + 1);
      return;
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
      return;
    }
  }

  const procedureApi = new ProcedureControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }))

  const handleCardClick = (procedure: any) => {
    const { procedureId, name, price, description } = procedure;
    setSelectedProcedure(null);
    setSelectedProcedure({ id: procedureId, name, price, description });
    setIsModalOpen(true);
  }

  const handleAddClick = () => {
    setSelectedProcedure({});
    setIsModalOpen(true);
  }

  const listProcedures = async (token?: string) => {
    return procedureApi.getProcedureList(
      auth.user?.physicianId,
      {
        page,
        size: 6,
      },
      {
        headers: {
          Authorization: `Bearer ${token ?? auth.token}`
        }
      }
    )
  }
  const fetchProcedures = async () => {
    const response = await listProcedures();
    if (response.data.statusCode === 200) {
      console.log("data", response);
      const data = (response.data.data as { content: [] }).content;
      const page = (response.data.data as { page: { number: number, totalPages: number } }).page;
      setPage(page.number);
      setMaxPage(page.totalPages);
      setProcedures(data);
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate)
    const secondResponse = await listProcedures(token);
    if (secondResponse.data.statusCode !== 200) {
      notifyError("Something went wrong");
      return;
    }
    const data = (secondResponse.data.data as { content: [] }).content;
    const page = (response.data.data as { page: { number: number, totalPages: number } }).page;
    setPage(page.number);
    setMaxPage(page.totalPages);
    setProcedures(data);
  }
  useEffect(() => {
    fetchProcedures().then(() => console.log("Successfully fetched physician's procedures"));
    console.log(procedures);
  }, [])
  return (
    <Card extra="w-full h-full mt-3">
      <div className="flex justify-end px-8 pt-5">
        <button onClick={handleAddClick}
          className="bg-heuplus-500 text-white px-4 py-2 rounded-lg hover:bg-heuplus-600"
        >
          Add New Procedure
        </button>
      </div>
      <div className="grid grid-cols-3 gap-4 rounded-lg px-8 py-5">
        {procedures.map((procedure) => (
          <div onClick={
            () => {
              handleCardClick(procedure)
            }
          } key={procedure.procedureId} className="flex flex-col items-center justify-between px-8 py-5 bg-heuplus-50 rounded-lg">
            <p className="font-bold">{procedure.name}</p>
            <p className="">{procedure.description ?? ""}</p>
            <p className="text-heuplus-500">{procedure.price}$</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center gap-2 mb-2">
        <button onClick={handlePreviousPage} disabled={page === 0} className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}>
          <MdChevronLeft />
        </button>
        <p>{page + 1} / {maxPage}</p>
        <button onClick={handleNextPage} disabled={page === maxPage - 1} className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}>
          <MdChevronRight />
        </button>
      </div>
      <ProcedureModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProcedure(null);
        }}
        procedure={selectedProcedure}
        fetchProcedures={fetchProcedures}
      />
    </Card>
  )
}

export default Procedures;
import Delete from "../../../components/settings/Delete";
import NewsLetter from "../../../components/settings/NewsLetter";
import Password from "../../../components/settings/Password";
import Profile from "./components/Profile";
import Session from "../../../components/settings/Session";
import TwoFactor from "../../../components/settings/TwoFactor";
import PhysicianSettings from "./components/PhysicianSettings";
import ThemeAndLanguage from "../../../components/settings/ThemeAndLanguage";

const Setting = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Profile />
        <PhysicianSettings />
        <Password /> {/* Change */}
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor />
        <NewsLetter />
        <ThemeAndLanguage />
        <Session /> {/* Change */}
        <Delete /> {/* Change */}
      </div>
    </div>
  );
};

export default Setting;

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, TwoFactorControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notify, notifyError } from "toast";

const DisableButton: React.FC<{ setEnabled: (value: boolean) => void }> = ({ setEnabled }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuthStore();
  const api = new TwoFactorControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))

  const disable2FARequest = async (token?: string) => {
    return api.disable2fa({
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    });
  }

  const disable2FA = async () => {
    let response = await disable2FARequest();
    if (response.data.statusCode === 200) {
      auth.updateUser({ requires2FA: false });
      notify("Disabled 2FA succesfully");
      setEnabled(false);
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate)
    response = await disable2FARequest(token);
    if (response.data.statusCode !== 200) {
      notifyError("Something went wrong");
      return;
    }
    auth.updateUser({ requires2FA: false });
    notify("Disabled 2FA succesfully");
    setEnabled(false);
  }
  return (
    <button onClick={disable2FA} className="p-2 rounded-[10px] font-bold text-red-500 bg-red-100 dark:bg-red-50">
      Disable
    </button>
  )
}

export default DisableButton;
import { useState } from "react";
import { Plugin } from "../types/plugin";
import { useAuthStore } from "store/useAuthStore";
import { AppSettingControllerApi, Configuration, UpdateAppSettingRequest } from "rest-client-sdk";
import { notify } from "toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Card from "components/card";
import MinioPlugin from "./MinioPlugin";
import AwsS3Plugin from "./AwsS3Plugin";
import GcpCloudStoragePlugin from "./GcpCloudStoragePlugin";

const FilePlugin = ({ plugin }: { plugin: Plugin | null }) => {
  const auth = useAuthStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = new AppSettingControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))
  const [selectedPlugin, setSelectedPlugin] = useState<string>(plugin?.pluginName ?? "minio");

  const [formData, setFormData] = useState<any>({ ...plugin?.pluginConfig });

  const updateFilePluginRequest = async (payload: UpdateAppSettingRequest, token?: string) => {
    return api.updateAppSetting(plugin.id, payload, {
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    });
  }

  const handleSave = async () => {
    const payload = {
      pluginName: selectedPlugin,
      pluginType: "FILE",
      pluginConfig: ""
    }
    let configData = { ...formData };
    if (selectedPlugin === "gcp-cs") {
      const parsed = await JSON.parse(formData.jsonKey)
      configData.jsonKey = parsed;
    }
    payload.pluginConfig = JSON.stringify(configData);
    let response = await updateFilePluginRequest(payload);
    if (response.data.statusCode === 200) {
      notify(t("File plugin updated successfully"));
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate);
    response = await updateFilePluginRequest(payload, token);
    if (response.data.statusCode !== 200) {
      notify(t("Something went wrong"));
      return;
    }

    notify(t("File plugin updated successfully"));
  }

  return (
    <Card className="w-full px-6 py-3">
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Storage Provider
        </label>
        <select
          value={selectedPlugin}
          onChange={(e) => setSelectedPlugin(e.target.value)}
          className="w-full max-w-xs px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
        >
          <option value="minio">MinIO</option>
          <option value="aws-s3">AWS S3</option>
          <option value="gcp-cs">GCP Cloud Storage</option>
        </select>
      </div>
      {selectedPlugin === "minio" && <MinioPlugin formData={formData} setFormData={setFormData} />}
      {selectedPlugin === "aws-s3" && <AwsS3Plugin formData={formData} setFormData={setFormData} />}
      {selectedPlugin === "gcp-cs" && <GcpCloudStoragePlugin formData={formData} setFormData={setFormData} />}
      <div className="mt-8">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-brand-500 text-white rounded-lg hover:bg-brand-600 
                   transition-colors duration-150 ease-in-out focus:outline-none 
                   focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default FilePlugin;
import physicianImg from "assets/img/avatars/physician-with-mask.png"
import { useAuthStore } from "store/useAuthStore";

const TotalAppointments = () => {
  const auth = useAuthStore();
  return (
    <div className="bg-heuplus-700 p-8 rounded-xl flex">
      <div className=" flex flex-col gap-2">
        <h2 className="text-3xl text-heuplus-50">{auth.user?.appointmentCount}</h2>
        <p className="text-heuplus-50 text-opacity-70">Total times you have seen to a physician.</p>
      </div>
      <img src={physicianImg} alt="physician" width={150} height={150} />
    </div>
  )
}

export default TotalAppointments;
/* tslint:disable */
/* eslint-disable */
/**
 * Heuplus REST API Reference
 * Heuplus REST API Reference
 *
 * The version of the OpenAPI document: 0.9.1-SNAPSHOT
 * Contact: info@heuplus.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'csrfToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationRequest
     */
    'otpCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'recaptchaToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'userAgent'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'messageType'?: ChatMessageMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'senderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'senderUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'receiverId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'receiverUsername'?: string;
    /**
     * 
     * @type {UserConnection}
     * @memberof ChatMessage
     */
    'userConnection'?: UserConnection;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'messageDeliveryStatusEnum'?: ChatMessageMessageDeliveryStatusEnumEnum;
    /**
     * 
     * @type {Array<MessageDeliveryStatusUpdate>}
     * @memberof ChatMessage
     */
    'messageDeliveryStatusUpdates'?: Array<MessageDeliveryStatusUpdate>;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    'timestamp'?: string;
}

export const ChatMessageMessageTypeEnum = {
    Chat: 'CHAT',
    Unseen: 'UNSEEN',
    FriendOnline: 'FRIEND_ONLINE',
    FriendOffline: 'FRIEND_OFFLINE',
    MessageDeliveryUpdate: 'MESSAGE_DELIVERY_UPDATE'
} as const;

export type ChatMessageMessageTypeEnum = typeof ChatMessageMessageTypeEnum[keyof typeof ChatMessageMessageTypeEnum];
export const ChatMessageMessageDeliveryStatusEnumEnum = {
    NotDelivered: 'NOT_DELIVERED',
    Delivered: 'DELIVERED',
    Seen: 'SEEN'
} as const;

export type ChatMessageMessageDeliveryStatusEnumEnum = typeof ChatMessageMessageDeliveryStatusEnumEnum[keyof typeof ChatMessageMessageDeliveryStatusEnumEnum];

/**
 * 
 * @export
 * @interface ContactUsRequest
 */
export interface ContactUsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactUsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsRequest
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreateAppointmentRequest
 */
export interface CreateAppointmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'appointmentTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'type'?: CreateAppointmentRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentRequest
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'participantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'procedureId'?: string;
}

export const CreateAppointmentRequestTypeEnum = {
    TeleconsultationVideo: 'TELECONSULTATION_VIDEO',
    ConsultationInPerson: 'CONSULTATION_IN_PERSON',
    TeleconsultationAudio: 'TELECONSULTATION_AUDIO',
    TeleconsultationChat: 'TELECONSULTATION_CHAT'
} as const;

export type CreateAppointmentRequestTypeEnum = typeof CreateAppointmentRequestTypeEnum[keyof typeof CreateAppointmentRequestTypeEnum];

/**
 * 
 * @export
 * @interface CreateMeetingRoomInformationRequest
 */
export interface CreateMeetingRoomInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingRoomInformationRequest
     */
    'patientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingRoomInformationRequest
     */
    'physicianId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingRoomInformationRequest
     */
    'appointmentId': string;
}
/**
 * 
 * @export
 * @interface CreateProcedureRequest
 */
export interface CreateProcedureRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProcedureRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProcedureRequest
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProcedureRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateRestrictionRequest
 */
export interface CreateRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface CreateSettingRequest
 */
export interface CreateSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSettingRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InitiateLoginRequest
 */
export interface InitiateLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof InitiateLoginRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InitiateLoginResponse
 */
export interface InitiateLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof InitiateLoginResponse
     */
    'salt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InitiateLoginResponse
     */
    'csrfToken'?: string;
}
/**
 * 
 * @export
 * @interface ListPhysiciansRequest
 */
export interface ListPhysiciansRequest {
    /**
     * 
     * @type {string}
     * @memberof ListPhysiciansRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPhysiciansRequest
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPhysiciansRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListPhysiciansRequest
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface MessageDeliveryStatusUpdate
 */
export interface MessageDeliveryStatusUpdate {
    /**
     * 
     * @type {string}
     * @memberof MessageDeliveryStatusUpdate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDeliveryStatusUpdate
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDeliveryStatusUpdate
     */
    'messageDeliveryStatusEnum'?: MessageDeliveryStatusUpdateMessageDeliveryStatusEnumEnum;
}

export const MessageDeliveryStatusUpdateMessageDeliveryStatusEnumEnum = {
    NotDelivered: 'NOT_DELIVERED',
    Delivered: 'DELIVERED',
    Seen: 'SEEN'
} as const;

export type MessageDeliveryStatusUpdateMessageDeliveryStatusEnumEnum = typeof MessageDeliveryStatusUpdateMessageDeliveryStatusEnumEnum[keyof typeof MessageDeliveryStatusUpdateMessageDeliveryStatusEnumEnum];

/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'salt': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'gender': RegisterRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'recaptchaToken': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'role'?: RegisterRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'userAgent'?: string;
}

export const RegisterRequestGenderEnum = {
    Female: 'FEMALE',
    Male: 'MALE',
    Other: 'OTHER'
} as const;

export type RegisterRequestGenderEnum = typeof RegisterRequestGenderEnum[keyof typeof RegisterRequestGenderEnum];
export const RegisterRequestRoleEnum = {
    User: 'ROLE_USER',
    Admin: 'ROLE_ADMIN',
    Patient: 'ROLE_PATIENT',
    Physician: 'ROLE_PHYSICIAN'
} as const;

export type RegisterRequestRoleEnum = typeof RegisterRequestRoleEnum[keyof typeof RegisterRequestRoleEnum];

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'salt': string;
}
/**
 * 
 * @export
 * @interface ResultWithDataInitiateLoginResponse
 */
export interface ResultWithDataInitiateLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof ResultWithDataInitiateLoginResponse
     */
    'message'?: string;
    /**
     * 
     * @type {InitiateLoginResponse}
     * @memberof ResultWithDataInitiateLoginResponse
     */
    'data'?: InitiateLoginResponse;
    /**
     * 
     * @type {number}
     * @memberof ResultWithDataInitiateLoginResponse
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface ResultWithDataObject
 */
export interface ResultWithDataObject {
    /**
     * 
     * @type {string}
     * @memberof ResultWithDataObject
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResultWithDataObject
     */
    'data'?: object;
    /**
     * 
     * @type {number}
     * @memberof ResultWithDataObject
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
    /**
     * 
     * @type {number}
     * @memberof SseEmitter
     */
    'timeout'?: number;
}
/**
 * 
 * @export
 * @interface UpdateAppSettingRequest
 */
export interface UpdateAppSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppSettingRequest
     */
    'pluginName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppSettingRequest
     */
    'pluginType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppSettingRequest
     */
    'pluginConfig': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientRequest
 */
export interface UpdatePatientRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'medications'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'medicalHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'insuranceProviderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'insurancePolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'allergies'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'lifestyle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'vaccinations'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePhysicianRequest
 */
export interface UpdatePhysicianRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'qualifications'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'educationRecord'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'previousExperience'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'profilePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'languages'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProcedureRequest
 */
export interface UpdateProcedureRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProcedureRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProcedureRequest
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProcedureRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRestrictionRequest
 */
export interface UpdateRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSettingRequest
 */
export interface UpdateSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UploadProfilePicRequest
 */
export interface UploadProfilePicRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadProfilePicRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface UserConnection
 */
export interface UserConnection {
    /**
     * 
     * @type {string}
     * @memberof UserConnection
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConnection
     */
    'connectionUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConnection
     */
    'convId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserConnection
     */
    'unSeen'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserConnection
     */
    'isOnline'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyOtpRequest
 */
export interface VerifyOtpRequest {
    /**
     * 
     * @type {number}
     * @memberof VerifyOtpRequest
     */
    'otpCode'?: number;
}
/**
 * 
 * @export
 * @interface VerifyTokenRequest
 */
export interface VerifyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    'token': string;
}

/**
 * AppSettingControllerApi - axios parameter creator
 * @export
 */
export const AppSettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists app\'s plugin settings
         * @summary List App Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/app-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates app\'s plugin settings
         * @summary Update App Setting
         * @param {string} appSettingId 
         * @param {UpdateAppSettingRequest} updateAppSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppSetting: async (appSettingId: string, updateAppSettingRequest: UpdateAppSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appSettingId' is not null or undefined
            assertParamExists('updateAppSetting', 'appSettingId', appSettingId)
            // verify required parameter 'updateAppSettingRequest' is not null or undefined
            assertParamExists('updateAppSetting', 'updateAppSettingRequest', updateAppSettingRequest)
            const localVarPath = `/api/v1/app-settings/{appSettingId}`
                .replace(`{${"appSettingId"}}`, encodeURIComponent(String(appSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSettingControllerApi - functional programming interface
 * @export
 */
export const AppSettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppSettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists app\'s plugin settings
         * @summary List App Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAppSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAppSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppSettingControllerApi.listAppSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates app\'s plugin settings
         * @summary Update App Setting
         * @param {string} appSettingId 
         * @param {UpdateAppSettingRequest} updateAppSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppSetting(appSettingId: string, updateAppSettingRequest: UpdateAppSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppSetting(appSettingId, updateAppSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppSettingControllerApi.updateAppSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppSettingControllerApi - factory interface
 * @export
 */
export const AppSettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppSettingControllerApiFp(configuration)
    return {
        /**
         * Lists app\'s plugin settings
         * @summary List App Setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppSettings(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.listAppSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates app\'s plugin settings
         * @summary Update App Setting
         * @param {string} appSettingId 
         * @param {UpdateAppSettingRequest} updateAppSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppSetting(appSettingId: string, updateAppSettingRequest: UpdateAppSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updateAppSetting(appSettingId, updateAppSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppSettingControllerApi - object-oriented interface
 * @export
 * @class AppSettingControllerApi
 * @extends {BaseAPI}
 */
export class AppSettingControllerApi extends BaseAPI {
    /**
     * Lists app\'s plugin settings
     * @summary List App Setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSettingControllerApi
     */
    public listAppSettings(options?: RawAxiosRequestConfig) {
        return AppSettingControllerApiFp(this.configuration).listAppSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates app\'s plugin settings
     * @summary Update App Setting
     * @param {string} appSettingId 
     * @param {UpdateAppSettingRequest} updateAppSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSettingControllerApi
     */
    public updateAppSetting(appSettingId: string, updateAppSettingRequest: UpdateAppSettingRequest, options?: RawAxiosRequestConfig) {
        return AppSettingControllerApiFp(this.configuration).updateAppSetting(appSettingId, updateAppSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppointmentControllerApi - axios parameter creator
 * @export
 */
export const AppointmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewAppointment: async (createAppointmentRequest: CreateAppointmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentRequest' is not null or undefined
            assertParamExists('createNewAppointment', 'createAppointmentRequest', createAppointmentRequest)
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentDetails: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentDetails', 'appointmentId', appointmentId)
            const localVarPath = `/api/v1/appointments/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppointments: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('listAppointments', 'pageable', pageable)
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentControllerApi - functional programming interface
 * @export
 */
export const AppointmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewAppointment(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewAppointment(createAppointmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.createNewAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentDetails(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentDetails(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.getAppointmentDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAppointments(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAppointments(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.listAppointments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppointmentControllerApi - factory interface
 * @export
 */
export const AppointmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentControllerApiFp(configuration)
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewAppointment(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.createNewAppointment(createAppointmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentDetails(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getAppointmentDetails(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppointments(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.listAppointments(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentControllerApi - object-oriented interface
 * @export
 * @class AppointmentControllerApi
 * @extends {BaseAPI}
 */
export class AppointmentControllerApi extends BaseAPI {
    /**
     * Creating a new appointment for the authorized patient
     * @summary Creates a new appointment
     * @param {CreateAppointmentRequest} createAppointmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public createNewAppointment(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).createNewAppointment(createAppointmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting an appointment with it\'s details
     * @summary Getting an appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public getAppointmentDetails(appointmentId: string, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).getAppointmentDetails(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing appointments of a user
     * @summary Listing appointments of a user
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public listAppointments(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).listAppointments(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppointmentSseControllerApi - axios parameter creator
 * @export
 */
export const AppointmentSseControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppointments: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('streamAppointments', 'authorization', authorization)
            const localVarPath = `/appointments/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentSseControllerApi - functional programming interface
 * @export
 */
export const AppointmentSseControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentSseControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamAppointments(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamAppointments(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentSseControllerApi.streamAppointments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppointmentSseControllerApi - factory interface
 * @export
 */
export const AppointmentSseControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentSseControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppointments(authorization: string, options?: RawAxiosRequestConfig): AxiosPromise<SseEmitter> {
            return localVarFp.streamAppointments(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentSseControllerApi - object-oriented interface
 * @export
 * @class AppointmentSseControllerApi
 * @extends {BaseAPI}
 */
export class AppointmentSseControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentSseControllerApi
     */
    public streamAppointments(authorization: string, options?: RawAxiosRequestConfig) {
        return AppointmentSseControllerApiFp(this.configuration).streamAppointments(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authenticates user with provided credentials and CSRF token
         * @summary Authentication handler for users
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCSRFTOKEN' is not null or undefined
            assertParamExists('authenticate', 'xCSRFTOKEN', xCSRFTOKEN)
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('authenticate', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/api/v1/auth/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCSRFTOKEN != null) {
                localVarHeaderParameter['X-CSRF-TOKEN'] = String(xCSRFTOKEN);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authenticates user with provided credentials, OTP code and CSRF token
         * @summary Authenticate With Otp
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithOtp: async (xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, xForwardedFor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCSRFTOKEN' is not null or undefined
            assertParamExists('authenticateWithOtp', 'xCSRFTOKEN', xCSRFTOKEN)
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('authenticateWithOtp', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/api/v1/auth/authenticate-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCSRFTOKEN != null) {
                localVarHeaderParameter['X-CSRF-TOKEN'] = String(xCSRFTOKEN);
            }

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }

            if (xForwardedFor != null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest: ForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/api/v1/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts the login process by providing necessary security tokens
         * @summary Login initiation handler
         * @param {InitiateLoginRequest} initiateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateLogin: async (initiateLoginRequest: InitiateLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateLoginRequest' is not null or undefined
            assertParamExists('initiateLogin', 'initiateLoginRequest', initiateLoginRequest)
            const localVarPath = `/api/v1/auth/initiate-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates a new access token using a valid refresh token
         * @summary Refresh access token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest: RefreshTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/api/v1/auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequest: RegisterRequest, userAgent?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('register', 'registerRequest', registerRequest)
            const localVarPath = `/api/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By taking credentials authenticates the user
         * @summary Verify token for users
         * @param {VerifyTokenRequest} verifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (verifyTokenRequest: VerifyTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTokenRequest' is not null or undefined
            assertParamExists('verifyToken', 'verifyTokenRequest', verifyTokenRequest)
            const localVarPath = `/api/v1/auth/verify-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Authenticates user with provided credentials and CSRF token
         * @summary Authentication handler for users
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(xCSRFTOKEN, authenticationRequest, userAgent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.authenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Authenticates user with provided credentials, OTP code and CSRF token
         * @summary Authenticate With Otp
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithOtp(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, xForwardedFor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateWithOtp(xCSRFTOKEN, authenticationRequest, userAgent, xForwardedFor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.authenticateWithOtp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.forgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Starts the login process by providing necessary security tokens
         * @summary Login initiation handler
         * @param {InitiateLoginRequest} initiateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateLogin(initiateLoginRequest: InitiateLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataInitiateLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateLogin(initiateLoginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.initiateLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generates a new access token using a valid refresh token
         * @summary Refresh access token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequest: RegisterRequest, userAgent?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequest, userAgent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * By taking credentials authenticates the user
         * @summary Verify token for users
         * @param {VerifyTokenRequest} verifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(verifyTokenRequest: VerifyTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(verifyTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.verifyToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationControllerApiFp(configuration)
    return {
        /**
         * Authenticates user with provided credentials and CSRF token
         * @summary Authentication handler for users
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.authenticate(xCSRFTOKEN, authenticationRequest, userAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * Authenticates user with provided credentials, OTP code and CSRF token
         * @summary Authenticate With Otp
         * @param {string} xCSRFTOKEN 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {string} [userAgent] 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithOtp(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, xForwardedFor?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.authenticateWithOtp(xCSRFTOKEN, authenticationRequest, userAgent, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts the login process by providing necessary security tokens
         * @summary Login initiation handler
         * @param {InitiateLoginRequest} initiateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateLogin(initiateLoginRequest: InitiateLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataInitiateLoginResponse> {
            return localVarFp.initiateLogin(initiateLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates a new access token using a valid refresh token
         * @summary Refresh access token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest: RegisterRequest, userAgent?: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.register(registerRequest, userAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * By taking credentials authenticates the user
         * @summary Verify token for users
         * @param {VerifyTokenRequest} verifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verifyTokenRequest: VerifyTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.verifyToken(verifyTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * Authenticates user with provided credentials and CSRF token
     * @summary Authentication handler for users
     * @param {string} xCSRFTOKEN 
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {string} [userAgent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public authenticate(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).authenticate(xCSRFTOKEN, authenticationRequest, userAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authenticates user with provided credentials, OTP code and CSRF token
     * @summary Authenticate With Otp
     * @param {string} xCSRFTOKEN 
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {string} [userAgent] 
     * @param {string} [xForwardedFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public authenticateWithOtp(xCSRFTOKEN: string, authenticationRequest: AuthenticationRequest, userAgent?: string, xForwardedFor?: string, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).authenticateWithOtp(xCSRFTOKEN, authenticationRequest, userAgent, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By taking credentials authenticates the user
     * @summary Login handler for users
     * @param {ForgotPasswordRequest} forgotPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts the login process by providing necessary security tokens
     * @summary Login initiation handler
     * @param {InitiateLoginRequest} initiateLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public initiateLogin(initiateLoginRequest: InitiateLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).initiateLogin(initiateLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates a new access token using a valid refresh token
     * @summary Refresh access token
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).refreshToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upon success creates a new account with USER role
     * @summary Registration handler for users
     * @param {RegisterRequest} registerRequest 
     * @param {string} [userAgent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public register(registerRequest: RegisterRequest, userAgent?: string, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).register(registerRequest, userAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By taking credentials authenticates the user
     * @summary Verify token for users
     * @param {VerifyTokenRequest} verifyTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public verifyToken(verifyTokenRequest: VerifyTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).verifyToken(verifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationControllerApi - axios parameter creator
 * @export
 */
export const ConversationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHistory: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/conversation/messageHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHistoryByConversationId: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getMessageHistoryByConversationId', 'conversationId', conversationId)
            const localVarPath = `/api/v1/conversation/messageHistory/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of unseen message counts
         * @summary Get unseen message count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnseenMessageCount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/conversation/unseenMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of unseen messages
         * @summary Get unseen messages
         * @param {string} fromUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnseenMessages: async (fromUserId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromUserId' is not null or undefined
            assertParamExists('getUnseenMessages', 'fromUserId', fromUserId)
            const localVarPath = `/api/v1/conversation/unseenMessages/{fromUserId}`
                .replace(`{${"fromUserId"}}`, encodeURIComponent(String(fromUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks messages as read
         * @summary Set read messages
         * @param {Array<ChatMessage>} chatMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadMessages: async (chatMessage: Array<ChatMessage>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatMessage' is not null or undefined
            assertParamExists('setReadMessages', 'chatMessage', chatMessage)
            const localVarPath = `/api/v1/conversation/setReadMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationControllerApi - functional programming interface
 * @export
 */
export const ConversationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageHistory(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageHistory(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationControllerApi.getMessageHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageHistoryByConversationId(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageHistoryByConversationId(conversationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationControllerApi.getMessageHistoryByConversationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of unseen message counts
         * @summary Get unseen message count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnseenMessageCount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnseenMessageCount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationControllerApi.getUnseenMessageCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of unseen messages
         * @summary Get unseen messages
         * @param {string} fromUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnseenMessages(fromUserId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnseenMessages(fromUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationControllerApi.getUnseenMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Marks messages as read
         * @summary Set read messages
         * @param {Array<ChatMessage>} chatMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReadMessages(chatMessage: Array<ChatMessage>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setReadMessages(chatMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationControllerApi.setReadMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConversationControllerApi - factory interface
 * @export
 */
export const ConversationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationControllerApiFp(configuration)
    return {
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHistory(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getMessageHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of messages between the user and the other user
         * @summary Get message history
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHistoryByConversationId(conversationId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getMessageHistoryByConversationId(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of unseen message counts
         * @summary Get unseen message count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnseenMessageCount(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getUnseenMessageCount(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of unseen messages
         * @summary Get unseen messages
         * @param {string} fromUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnseenMessages(fromUserId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getUnseenMessages(fromUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks messages as read
         * @summary Set read messages
         * @param {Array<ChatMessage>} chatMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadMessages(chatMessage: Array<ChatMessage>, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.setReadMessages(chatMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationControllerApi - object-oriented interface
 * @export
 * @class ConversationControllerApi
 * @extends {BaseAPI}
 */
export class ConversationControllerApi extends BaseAPI {
    /**
     * Returns a list of messages between the user and the other user
     * @summary Get message history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationControllerApi
     */
    public getMessageHistory(options?: RawAxiosRequestConfig) {
        return ConversationControllerApiFp(this.configuration).getMessageHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of messages between the user and the other user
     * @summary Get message history
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationControllerApi
     */
    public getMessageHistoryByConversationId(conversationId: string, options?: RawAxiosRequestConfig) {
        return ConversationControllerApiFp(this.configuration).getMessageHistoryByConversationId(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of unseen message counts
     * @summary Get unseen message count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationControllerApi
     */
    public getUnseenMessageCount(options?: RawAxiosRequestConfig) {
        return ConversationControllerApiFp(this.configuration).getUnseenMessageCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of unseen messages
     * @summary Get unseen messages
     * @param {string} fromUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationControllerApi
     */
    public getUnseenMessages(fromUserId: string, options?: RawAxiosRequestConfig) {
        return ConversationControllerApiFp(this.configuration).getUnseenMessages(fromUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks messages as read
     * @summary Set read messages
     * @param {Array<ChatMessage>} chatMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationControllerApi
     */
    public setReadMessages(chatMessage: Array<ChatMessage>, options?: RawAxiosRequestConfig) {
        return ConversationControllerApiFp(this.configuration).setReadMessages(chatMessage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeetingRoomInformationControllerApi - axios parameter creator
 * @export
 */
export const MeetingRoomInformationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new meeting room
         * @summary Create meeting room
         * @param {CreateMeetingRoomInformationRequest} createMeetingRoomInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingRoomInfo: async (createMeetingRoomInformationRequest: CreateMeetingRoomInformationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMeetingRoomInformationRequest' is not null or undefined
            assertParamExists('createMeetingRoomInfo', 'createMeetingRoomInformationRequest', createMeetingRoomInformationRequest)
            const localVarPath = `/api/v1/meeting-room`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMeetingRoomInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get meeting room information by meeting room information id
         * @summary Get meeting room information
         * @param {string} meetingRoomInformationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingRoomInfoDetails: async (meetingRoomInformationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingRoomInformationId' is not null or undefined
            assertParamExists('getMeetingRoomInfoDetails', 'meetingRoomInformationId', meetingRoomInformationId)
            const localVarPath = `/api/v1/meeting-room/{meetingRoomInformationId}`
                .replace(`{${"meetingRoomInformationId"}}`, encodeURIComponent(String(meetingRoomInformationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingRoomInformationControllerApi - functional programming interface
 * @export
 */
export const MeetingRoomInformationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingRoomInformationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new meeting room
         * @summary Create meeting room
         * @param {CreateMeetingRoomInformationRequest} createMeetingRoomInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingRoomInfo(createMeetingRoomInformationRequest: CreateMeetingRoomInformationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingRoomInfo(createMeetingRoomInformationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeetingRoomInformationControllerApi.createMeetingRoomInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get meeting room information by meeting room information id
         * @summary Get meeting room information
         * @param {string} meetingRoomInformationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingRoomInfoDetails(meetingRoomInformationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingRoomInfoDetails(meetingRoomInformationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeetingRoomInformationControllerApi.getMeetingRoomInfoDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MeetingRoomInformationControllerApi - factory interface
 * @export
 */
export const MeetingRoomInformationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingRoomInformationControllerApiFp(configuration)
    return {
        /**
         * Create a new meeting room
         * @summary Create meeting room
         * @param {CreateMeetingRoomInformationRequest} createMeetingRoomInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingRoomInfo(createMeetingRoomInformationRequest: CreateMeetingRoomInformationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.createMeetingRoomInfo(createMeetingRoomInformationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get meeting room information by meeting room information id
         * @summary Get meeting room information
         * @param {string} meetingRoomInformationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingRoomInfoDetails(meetingRoomInformationId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getMeetingRoomInfoDetails(meetingRoomInformationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingRoomInformationControllerApi - object-oriented interface
 * @export
 * @class MeetingRoomInformationControllerApi
 * @extends {BaseAPI}
 */
export class MeetingRoomInformationControllerApi extends BaseAPI {
    /**
     * Create a new meeting room
     * @summary Create meeting room
     * @param {CreateMeetingRoomInformationRequest} createMeetingRoomInformationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRoomInformationControllerApi
     */
    public createMeetingRoomInfo(createMeetingRoomInformationRequest: CreateMeetingRoomInformationRequest, options?: RawAxiosRequestConfig) {
        return MeetingRoomInformationControllerApiFp(this.configuration).createMeetingRoomInfo(createMeetingRoomInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get meeting room information by meeting room information id
     * @summary Get meeting room information
     * @param {string} meetingRoomInformationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRoomInformationControllerApi
     */
    public getMeetingRoomInfoDetails(meetingRoomInformationId: string, options?: RawAxiosRequestConfig) {
        return MeetingRoomInformationControllerApiFp(this.configuration).getMeetingRoomInfoDetails(meetingRoomInformationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactUsRequest} contactUsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactUsEmail: async (contactUsRequest: ContactUsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactUsRequest' is not null or undefined
            assertParamExists('sendContactUsEmail', 'contactUsRequest', contactUsRequest)
            const localVarPath = `/api/v1/notifications/contact-us`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactUsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactUsRequest} contactUsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendContactUsEmail(contactUsRequest: ContactUsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendContactUsEmail(contactUsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationControllerApi.sendContactUsEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactUsRequest} contactUsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactUsEmail(contactUsRequest: ContactUsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.sendContactUsEmail(contactUsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {ContactUsRequest} contactUsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public sendContactUsEmail(contactUsRequest: ContactUsRequest, options?: RawAxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).sendContactUsEmail(contactUsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientControllerApi - axios parameter creator
 * @export
 */
export const PatientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of patients with pagination
         * @summary Gets a list of patients
         * @param {Pageable} pageable 
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientList: async (pageable: Pageable, physicianId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientList', 'pageable', pageable)
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('getPatientList', 'physicianId', physicianId)
            const localVarPath = `/api/v1/patients/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient: async (updatePatientRequest: UpdatePatientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientRequest' is not null or undefined
            assertParamExists('updatePatient', 'updatePatientRequest', updatePatientRequest)
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientControllerApi - functional programming interface
 * @export
 */
export const PatientControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.getPatientDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the list of patients with pagination
         * @summary Gets a list of patients
         * @param {Pageable} pageable 
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientList(pageable: Pageable, physicianId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientList(pageable, physicianId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.getPatientList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatient(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatient(updatePatientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.updatePatient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientControllerApi - factory interface
 * @export
 */
export const PatientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientControllerApiFp(configuration)
    return {
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetails(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPatientDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of patients with pagination
         * @summary Gets a list of patients
         * @param {Pageable} pageable 
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientList(pageable: Pageable, physicianId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPatientList(pageable, physicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatient(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updatePatient(updatePatientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientControllerApi - object-oriented interface
 * @export
 * @class PatientControllerApi
 * @extends {BaseAPI}
 */
export class PatientControllerApi extends BaseAPI {
    /**
     * Using the authentication context gets a patient\'s details
     * @summary Gets a Patient\'s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatientDetails(options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatientDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of patients with pagination
     * @summary Gets a list of patients
     * @param {Pageable} pageable 
     * @param {string} physicianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public getPatientList(pageable: Pageable, physicianId: string, options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).getPatientList(pageable, physicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating a patients details
     * @summary Update a patient
     * @param {UpdatePatientRequest} updatePatientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public updatePatient(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).updatePatient(updatePatientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PhysicianControllerApi - axios parameter creator
 * @export
 */
export const PhysicianControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianAvailability: async (physicianId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('getPhysicianAvailability', 'physicianId', physicianId)
            const localVarPath = `/api/v1/physicians/{physicianId}/availability`
                .replace(`{${"physicianId"}}`, encodeURIComponent(String(physicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/physicians/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianDetails1: async (physicianId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('getPhysicianDetails1', 'physicianId', physicianId)
            const localVarPath = `/api/v1/physicians/{physicianId}`
                .replace(`{${"physicianId"}}`, encodeURIComponent(String(physicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {ListPhysiciansRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianList: async (pageable: Pageable, request: ListPhysiciansRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPhysicianList', 'pageable', pageable)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('getPhysicianList', 'request', request)
            const localVarPath = `/api/v1/physicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhysician: async (updatePhysicianRequest: UpdatePhysicianRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePhysicianRequest' is not null or undefined
            assertParamExists('updatePhysician', 'updatePhysicianRequest', updatePhysicianRequest)
            const localVarPath = `/api/v1/physicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePhysicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhysicianControllerApi - functional programming interface
 * @export
 */
export const PhysicianControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhysicianControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicianAvailability(physicianId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhysicianAvailability(physicianId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.getPhysicianAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicianDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhysicianDetails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.getPhysicianDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicianDetails1(physicianId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhysicianDetails1(physicianId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.getPhysicianDetails1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {ListPhysiciansRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicianList(pageable: Pageable, request: ListPhysiciansRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhysicianList(pageable, request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.getPhysicianList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhysician(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhysician(updatePhysicianRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.updatePhysician']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PhysicianControllerApi - factory interface
 * @export
 */
export const PhysicianControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhysicianControllerApiFp(configuration)
    return {
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianAvailability(physicianId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPhysicianAvailability(physicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianDetails(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPhysicianDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianDetails1(physicianId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPhysicianDetails1(physicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {ListPhysiciansRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicianList(pageable: Pageable, request: ListPhysiciansRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getPhysicianList(pageable, request, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhysician(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updatePhysician(updatePhysicianRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhysicianControllerApi - object-oriented interface
 * @export
 * @class PhysicianControllerApi
 * @extends {BaseAPI}
 */
export class PhysicianControllerApi extends BaseAPI {
    /**
     * Gets a Physician\'s availability details
     * @summary Gets a Physician\'s availability details
     * @param {string} physicianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public getPhysicianAvailability(physicianId: string, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).getPhysicianAvailability(physicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
     * @summary Gets a Physician\'s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public getPhysicianDetails(options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).getPhysicianDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
     * @summary Gets a Physician\'s details
     * @param {string} physicianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public getPhysicianDetails1(physicianId: string, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).getPhysicianDetails1(physicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of physicians with pagination
     * @summary Gets a list of physicians
     * @param {Pageable} pageable 
     * @param {ListPhysiciansRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public getPhysicianList(pageable: Pageable, request: ListPhysiciansRequest, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).getPhysicianList(pageable, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a physicians details
     * @summary Updates a physician
     * @param {UpdatePhysicianRequest} updatePhysicianRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public updatePhysician(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).updatePhysician(updatePhysicianRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcedureControllerApi - axios parameter creator
 * @export
 */
export const ProcedureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcedure: async (createProcedureRequest: CreateProcedureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProcedureRequest' is not null or undefined
            assertParamExists('createProcedure', 'createProcedureRequest', createProcedureRequest)
            const localVarPath = `/api/v1/procedures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProcedureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcedure: async (procedureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureId' is not null or undefined
            assertParamExists('deleteProcedure', 'procedureId', procedureId)
            const localVarPath = `/api/v1/procedures/{procedureId}`
                .replace(`{${"procedureId"}}`, encodeURIComponent(String(procedureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcedureDetails: async (procedureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureId' is not null or undefined
            assertParamExists('getProcedureDetails', 'procedureId', procedureId)
            const localVarPath = `/api/v1/procedures/{procedureId}`
                .replace(`{${"procedureId"}}`, encodeURIComponent(String(procedureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcedureList: async (physicianId: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('getProcedureList', 'physicianId', physicianId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getProcedureList', 'pageable', pageable)
            const localVarPath = `/api/v1/procedures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcedure: async (procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureId' is not null or undefined
            assertParamExists('updateProcedure', 'procedureId', procedureId)
            // verify required parameter 'updateProcedureRequest' is not null or undefined
            assertParamExists('updateProcedure', 'updateProcedureRequest', updateProcedureRequest)
            const localVarPath = `/api/v1/procedures/{procedureId}`
                .replace(`{${"procedureId"}}`, encodeURIComponent(String(procedureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProcedureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcedureControllerApi - functional programming interface
 * @export
 */
export const ProcedureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcedureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProcedure(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProcedure(createProcedureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.createProcedure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProcedure(procedureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProcedure(procedureId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.deleteProcedure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcedureDetails(procedureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcedureDetails(procedureId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.getProcedureDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcedureList(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcedureList(physicianId, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.getProcedureList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProcedure(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProcedure(procedureId, updateProcedureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.updateProcedure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProcedureControllerApi - factory interface
 * @export
 */
export const ProcedureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcedureControllerApiFp(configuration)
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcedure(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.createProcedure(createProcedureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcedure(procedureId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.deleteProcedure(procedureId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcedureDetails(procedureId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getProcedureDetails(procedureId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcedureList(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getProcedureList(physicianId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcedure(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updateProcedure(procedureId, updateProcedureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcedureControllerApi - object-oriented interface
 * @export
 * @class ProcedureControllerApi
 * @extends {BaseAPI}
 */
export class ProcedureControllerApi extends BaseAPI {
    /**
     * Creating a new procedure for the authorized physician
     * @summary Creates a new procedure
     * @param {CreateProcedureRequest} createProcedureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public createProcedure(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).createProcedure(createProcedureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a procedure\'s details for the authorized physician
     * @summary Updates a procedure
     * @param {string} procedureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public deleteProcedure(procedureId: string, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).deleteProcedure(procedureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a procedure with it\'s details
     * @summary Getting a procedure
     * @param {string} procedureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public getProcedureDetails(procedureId: string, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).getProcedureDetails(procedureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing procedures of a physician
     * @summary Listing procedures of a physician
     * @param {string} physicianId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public getProcedureList(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).getProcedureList(physicianId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a procedure\'s details for the authorized physician
     * @summary Updates a procedure
     * @param {string} procedureId 
     * @param {UpdateProcedureRequest} updateProcedureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public updateProcedure(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).updateProcedure(procedureId, updateProcedureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RestrictionControllerApi - axios parameter creator
 * @export
 */
export const RestrictionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestriction: async (createRestrictionRequest: CreateRestrictionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRestrictionRequest' is not null or undefined
            assertParamExists('createRestriction', 'createRestrictionRequest', createRestrictionRequest)
            const localVarPath = `/api/v1/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRestrictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestriction: async (restrictionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('deleteRestriction', 'restrictionId', restrictionId)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestrictionDetails: async (restrictionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('getRestrictionDetails', 'restrictionId', restrictionId)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestrictionList: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getRestrictionList', 'pageable', pageable)
            const localVarPath = `/api/v1/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestriction: async (restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('updateRestriction', 'restrictionId', restrictionId)
            // verify required parameter 'updateRestrictionRequest' is not null or undefined
            assertParamExists('updateRestriction', 'updateRestrictionRequest', updateRestrictionRequest)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRestrictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestrictionControllerApi - functional programming interface
 * @export
 */
export const RestrictionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestrictionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRestriction(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRestriction(createRestrictionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.createRestriction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRestriction(restrictionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRestriction(restrictionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.deleteRestriction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestrictionDetails(restrictionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestrictionDetails(restrictionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.getRestrictionDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestrictionList(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestrictionList(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.getRestrictionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRestriction(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRestriction(restrictionId, updateRestrictionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.updateRestriction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RestrictionControllerApi - factory interface
 * @export
 */
export const RestrictionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestrictionControllerApiFp(configuration)
    return {
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestriction(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.createRestriction(createRestrictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestriction(restrictionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.deleteRestriction(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestrictionDetails(restrictionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getRestrictionDetails(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestrictionList(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getRestrictionList(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestriction(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updateRestriction(restrictionId, updateRestrictionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RestrictionControllerApi - object-oriented interface
 * @export
 * @class RestrictionControllerApi
 * @extends {BaseAPI}
 */
export class RestrictionControllerApi extends BaseAPI {
    /**
     * Creating a new restriction for the authorized physician
     * @summary Creates a new restriction
     * @param {CreateRestrictionRequest} createRestrictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public createRestriction(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).createRestriction(createRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a restriction\'s details for the authorized physician
     * @summary Deletes a restriction
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public deleteRestriction(restrictionId: string, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).deleteRestriction(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a restriction with it\'s details
     * @summary Getting a restriction
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public getRestrictionDetails(restrictionId: string, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).getRestrictionDetails(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing restrictions of a physician
     * @summary Listing restrictions of a physician
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public getRestrictionList(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).getRestrictionList(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a restriction\'s details for the authorized physician
     * @summary Updates a restriction
     * @param {string} restrictionId 
     * @param {UpdateRestrictionRequest} updateRestrictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public updateRestriction(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).updateRestriction(restrictionId, updateRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingControllerApi - axios parameter creator
 * @export
 */
export const SettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSetting: async (createSettingRequest: CreateSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSettingRequest' is not null or undefined
            assertParamExists('createSetting', 'createSettingRequest', createSettingRequest)
            const localVarPath = `/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingDetails: async (settingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('getSettingDetails', 'settingId', settingId)
            const localVarPath = `/api/v1/settings/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting: async (settingId: string, updateSettingRequest: UpdateSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('updateSetting', 'settingId', settingId)
            // verify required parameter 'updateSettingRequest' is not null or undefined
            assertParamExists('updateSetting', 'updateSettingRequest', updateSettingRequest)
            const localVarPath = `/api/v1/settings/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingControllerApi - functional programming interface
 * @export
 */
export const SettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSetting(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSetting(createSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.createSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingDetails(settingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingDetails(settingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.getSettingDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.getSettingList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSetting(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSetting(settingId, updateSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.updateSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingControllerApi - factory interface
 * @export
 */
export const SettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingControllerApiFp(configuration)
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSetting(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.createSetting(createSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingDetails(settingId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getSettingDetails(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingList(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getSettingList(options).then((request) => request(axios, basePath));
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.updateSetting(settingId, updateSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingControllerApi - object-oriented interface
 * @export
 * @class SettingControllerApi
 * @extends {BaseAPI}
 */
export class SettingControllerApi extends BaseAPI {
    /**
     * Creating a new setting for the authorized physician or patient
     * @summary Creates a new setting
     * @param {CreateSettingRequest} createSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public createSetting(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).createSetting(createSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a setting with it\'s details
     * @summary Getting a setting
     * @param {string} settingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getSettingDetails(settingId: string, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getSettingDetails(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a list of settings with it\'s details
     * @summary Getting a list of settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getSettingList(options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getSettingList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating a setting for the authorized physician or patient
     * @summary Updates a setting
     * @param {string} settingId 
     * @param {UpdateSettingRequest} updateSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public updateSetting(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).updateSetting(settingId, updateSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShortUrlControllerApi - axios parameter creator
 * @export
 */
export const ShortUrlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortUrlDetails: async (shortUrlKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortUrlKey' is not null or undefined
            assertParamExists('getShortUrlDetails', 'shortUrlKey', shortUrlKey)
            const localVarPath = `/api/v1/shortUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shortUrlKey !== undefined) {
                localVarQueryParameter['shortUrlKey'] = shortUrlKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortUrlControllerApi - functional programming interface
 * @export
 */
export const ShortUrlControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortUrlControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortUrlDetails(shortUrlKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortUrlDetails(shortUrlKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortUrlControllerApi.getShortUrlDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShortUrlControllerApi - factory interface
 * @export
 */
export const ShortUrlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortUrlControllerApiFp(configuration)
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortUrlDetails(shortUrlKey: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getShortUrlDetails(shortUrlKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortUrlControllerApi - object-oriented interface
 * @export
 * @class ShortUrlControllerApi
 * @extends {BaseAPI}
 */
export class ShortUrlControllerApi extends BaseAPI {
    /**
     * Getting a short url with it\'s details
     * @summary Getting a shortUrl
     * @param {string} shortUrlKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortUrlControllerApi
     */
    public getShortUrlDetails(shortUrlKey: string, options?: RawAxiosRequestConfig) {
        return ShortUrlControllerApiFp(this.configuration).getShortUrlDetails(shortUrlKey, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorControllerApi - axios parameter creator
 * @export
 */
export const TwoFactorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Disables 2FA for the authenticated user
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable2fa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/2fa/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enables 2FA for the authenticated user and returns the secret key
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enable2fa: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/2fa/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies 2FA for the authenticated user
         * @summary Verify 2FA
         * @param {VerifyOtpRequest} verifyOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify2fa: async (verifyOtpRequest: VerifyOtpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOtpRequest' is not null or undefined
            assertParamExists('verify2fa', 'verifyOtpRequest', verifyOtpRequest)
            const localVarPath = `/api/v1/2fa/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorControllerApi - functional programming interface
 * @export
 */
export const TwoFactorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Disables 2FA for the authenticated user
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disable2fa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disable2fa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorControllerApi.disable2fa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Enables 2FA for the authenticated user and returns the secret key
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enable2fa(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enable2fa(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorControllerApi.enable2fa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verifies 2FA for the authenticated user
         * @summary Verify 2FA
         * @param {VerifyOtpRequest} verifyOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verify2fa(verifyOtpRequest: VerifyOtpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verify2fa(verifyOtpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorControllerApi.verify2fa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorControllerApi - factory interface
 * @export
 */
export const TwoFactorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorControllerApiFp(configuration)
    return {
        /**
         * Disables 2FA for the authenticated user
         * @summary Disable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable2fa(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.disable2fa(options).then((request) => request(axios, basePath));
        },
        /**
         * Enables 2FA for the authenticated user and returns the secret key
         * @summary Enable 2FA
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enable2fa(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.enable2fa(options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies 2FA for the authenticated user
         * @summary Verify 2FA
         * @param {VerifyOtpRequest} verifyOtpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify2fa(verifyOtpRequest: VerifyOtpRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.verify2fa(verifyOtpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorControllerApi - object-oriented interface
 * @export
 * @class TwoFactorControllerApi
 * @extends {BaseAPI}
 */
export class TwoFactorControllerApi extends BaseAPI {
    /**
     * Disables 2FA for the authenticated user
     * @summary Disable 2FA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorControllerApi
     */
    public disable2fa(options?: RawAxiosRequestConfig) {
        return TwoFactorControllerApiFp(this.configuration).disable2fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enables 2FA for the authenticated user and returns the secret key
     * @summary Enable 2FA
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorControllerApi
     */
    public enable2fa(options?: RawAxiosRequestConfig) {
        return TwoFactorControllerApiFp(this.configuration).enable2fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies 2FA for the authenticated user
     * @summary Verify 2FA
     * @param {VerifyOtpRequest} verifyOtpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorControllerApi
     */
    public verify2fa(verifyOtpRequest: VerifyOtpRequest, options?: RawAxiosRequestConfig) {
        return TwoFactorControllerApiFp(this.configuration).verify2fa(verifyOtpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of online users
         * @summary Get online users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/online`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a map of user subscriptions
         * @summary Get user subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invalidates the user\'s refresh token and increments the token version
         * @summary Logout handler for users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upon success resets the user\'s password
         * @summary Reset password handler for users
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/users/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates user\'s newsletter subscription preference in database
         * @summary Toggles user\'s newsletter preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleNewsletterSubscribed: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads User\'s profile picture to S3 and updates the User
         * @summary Uploads User profile picture
         * @param {UploadProfilePicRequest} [uploadProfilePicRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePic: async (uploadProfilePicRequest?: UploadProfilePicRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/profile/picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadProfilePicRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of online users
         * @summary Get online users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnlineUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnlineUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getOnlineUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a map of user subscriptions
         * @summary Get user subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Invalidates the user\'s refresh token and increments the token version
         * @summary Logout handler for users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upon success resets the user\'s password
         * @summary Reset password handler for users
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates user\'s newsletter subscription preference in database
         * @summary Toggles user\'s newsletter preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleNewsletterSubscribed(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleNewsletterSubscribed(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.toggleNewsletterSubscribed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads User\'s profile picture to S3 and updates the User
         * @summary Uploads User profile picture
         * @param {UploadProfilePicRequest} [uploadProfilePicRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilePic(uploadProfilePicRequest?: UploadProfilePicRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfilePic(uploadProfilePicRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.uploadProfilePic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * Returns a list of online users
         * @summary Get online users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnlineUsers(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getOnlineUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a map of user subscriptions
         * @summary Get user subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Invalidates the user\'s refresh token and increments the token version
         * @summary Logout handler for users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Upon success resets the user\'s password
         * @summary Reset password handler for users
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates user\'s newsletter subscription preference in database
         * @summary Toggles user\'s newsletter preference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleNewsletterSubscribed(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.toggleNewsletterSubscribed(options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads User\'s profile picture to S3 and updates the User
         * @summary Uploads User profile picture
         * @param {UploadProfilePicRequest} [uploadProfilePicRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePic(uploadProfilePicRequest?: UploadProfilePicRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.uploadProfilePic(uploadProfilePicRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * Returns a list of online users
     * @summary Get online users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getOnlineUsers(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getOnlineUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a map of user subscriptions
     * @summary Get user subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getSubscriptions(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invalidates the user\'s refresh token and increments the token version
     * @summary Logout handler for users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upon success resets the user\'s password
     * @summary Reset password handler for users
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates user\'s newsletter subscription preference in database
     * @summary Toggles user\'s newsletter preference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public toggleNewsletterSubscribed(options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).toggleNewsletterSubscribed(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads User\'s profile picture to S3 and updates the User
     * @summary Uploads User profile picture
     * @param {UploadProfilePicRequest} [uploadProfilePicRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public uploadProfilePic(uploadProfilePicRequest?: UploadProfilePicRequest, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).uploadProfilePic(uploadProfilePicRequest, options).then((request) => request(this.axios, this.basePath));
    }
}




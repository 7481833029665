const SmtpPlugin = (props: { formData: any, setFormData: (formData: any) => void }) => {
  const { formData, setFormData } = props;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: checked,
    }));
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 max-w-full">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Host
        </label>
        <input
          type="text"
          name="host"
          value={formData.host}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Host"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Port
        </label>
        <input
          type="number"
          name="port"
          value={formData.port}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Port"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Username
        </label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Username"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Password
        </label>
        <input
          type="text"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Password"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Enable TLS
        </label>
        <input
          type="checkbox"
          name="tls"
          value={formData.tls}
          onChange={handleCheckboxChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Enable Auth
        </label>
        <input
          type="checkbox"
          name="auth"
          value={formData.auth}
          onChange={handleCheckboxChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Protocol
        </label>
        <input
          type="text"
          name="protocol"
          value={formData.protocol}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter Protocol"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          From Mail Address
        </label>
        <input
          type="text"
          name="from"
          value={formData.from}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your From Mail Address"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Template ID Contact Us
        </label>
        <input
          type="text"
          name="templateIdContactUs"
          value={formData.templateIdContactUs}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Template ID Contact Us"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Template ID Forgot Password
        </label>
        <input
          type="text"
          name="templateIdForgotPassword"
          value={formData.templateIdForgotPassword}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Template ID Forgot Password"
        />
      </div>
    </div>
  )
}

export default SmtpPlugin;
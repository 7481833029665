import Card from "components/card";
import { sha256 } from "js-sha256";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, UserControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { notify, notifyError } from "toast";

const Password = () => {
  const auth = useAuthStore();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = new UserControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))

  const resetPasswordRequest = async (token?: string) => {
    const salt = crypto
      .getRandomValues(new Uint8Array(16))
      .reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    const password = sha256(newPassword + salt);
    return api.resetPassword(
      {
        password,
        salt
      },
      {
        headers: {
          Authorization: `Bearer ${token ?? auth.token}`
        }
      }
    );
  }

  const handleChangePassword = async () => {
    if (newPassword.length < 8) {
      notify(t("Password must be at least 8 characters long"));
      return;
    }
    if (newPassword.search(/[a-z]/) < 0) {
      notify(t("Password must contain at least one lowercase letter"));
      return;
    }
    if (newPassword.search(/[A-Z]/) < 0) {
      notify(t("Password must contain at least one uppercase letter"));
      return;
    }
    if (newPassword.search(/[0-9]/) < 0) {
      notify(t("Password must contain at least one digit"));
      return;
    }
    if (newPassword !== newPasswordConfirmation) {
      notify(t("New password and confirmation password do not match"));
      return;
    }

    let response = await resetPasswordRequest();
    if (response.data.statusCode === 200) {
      notify(t("Password changed successfully"));
      setNewPassword("");
      setNewPasswordConfirmation("");
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await resetPasswordRequest(token);
    if (response.data.statusCode !== 200) {
      notifyError(t("Something went wrong"));
      setNewPassword("");
      setNewPasswordConfirmation("");
      return;
    }
    notify(t("Password changed successfully"));
    setNewPassword("");
    setNewPasswordConfirmation("");
  }

  return (
    <Card extra={"w-full p-4"}>
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      <div className="mt-7 grid grid-cols-1 gap-3">
        <div className="flex flex-col gap-2">
          <label htmlFor="newPassword" className="text-sm text-navy-700 dark:text-white font-bold">
            New Password
          </label>
          <input
            id="newPassword"
            type="password"
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            placeholder="Your New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="newPasswordConfirmation" className="text-sm text-navy-700 dark:text-white font-bold">
            New Password Confirmation
          </label>
          <input
            id="newPasswordConfirmation"
            type="password"
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            placeholder="Confirm New Password"
            onChange={(e) => setNewPasswordConfirmation(e.target.value)}
          />
        </div>
        <div className="mt-12 flex w-full justify-end">
          <button onClick={handleChangePassword} className="linear rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Change Password
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Password;

import { TopBarProps } from "../types/topBarProps";

const TopBar = ({ activeTab, setActiveTab }: TopBarProps) => {
  return (
    <div className="w-full px-5 md:px-8 py-5 mb-8 rounded-2xl bg-white shadow-sm">
      <div className="flex flex-row gap-5">
        <span
          className={`text-sm font-medium cursor-pointer ${activeTab === 'file'
            ? 'text-brand-500'
            : 'text-gray-700 hover:text-gray-900'
            }`}
          onClick={() => setActiveTab('file')}
        >
          File Plugin
        </span>
        <span
          className={`text-sm font-medium cursor-pointer ${activeTab === 'email'
            ? 'text-brand-500'
            : 'text-gray-700 hover:text-gray-900'
            }`}
          onClick={() => setActiveTab('email')}
        >
          Email Plugin
        </span>
      </div>
    </div>
  );
};

export default TopBar;
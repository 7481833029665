import Card from "components/card";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "store/useAuthStore";
import { useState } from "react";
import DisableButton from "./DisableButton";
import EnableTwoFactor from "./EnableTwoFactor";
import { Configuration } from "rest-client-sdk";
import { TwoFactorControllerApi } from "rest-client-sdk";
import { notifyError } from "toast";

const TwoFactor = () => {
  const auth = useAuthStore();
  const [enabled, setEnabled] = useState(auth.user?.requires2FA || false)
  const [showEnable, setShowEnable] = useState(false);
  const [secret, setSecret] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const api = new TwoFactorControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }))

  const enableTwoFactorRequest = async (token?: string) => {
    return api.enable2fa({
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    })
  }

  const handleEnableClick = async () => {
    let response = await enableTwoFactorRequest();
    if (response.data.statusCode === 200) {
      const data = response.data.data as { secretKey: string, qrCodeUrl: string }
      setSecret(data.secretKey);
      setUrl(data.qrCodeUrl);
      setShowEnable(true);
      return;
    }
    const token = await auth.refreshTokenAction(t, navigate);
    response = await enableTwoFactorRequest(token);
    if (response.data.statusCode !== 200) {
      notifyError("Failed to enable two-factor authentication");
      return;
    }
    const data = response.data.data as { secretKey: string, qrCodeUrl: string }
    setSecret(data.secretKey);
    setUrl(data.qrCodeUrl);
    setEnabled(true);
  }

  return (
    <Card extra={"w-full px-8 py-8"}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Two-Factor Authentication
        </h4>
        <div className={`
          mt-1 flex items-center justify-center rounded-[10px]  px-3 py-1 text-sm font-bold uppercase ${enabled ?
            "text-green-500 bg-green-100 dark:bg-green-50" :
            "text-red-500 bg-red-100 dark:bg-red-50"}`}>
          <p>{enabled ? "Enabled" : "Disabled"}</p>
        </div>
      </div>
      {
        showEnable && <EnableTwoFactor secret={secret} url={url} setShowEnable={setShowEnable} setEnabled={setEnabled} />
      }
      {
        !showEnable &&
        <div className="flex items-center justify-center mt-3 w-full">
          {
            enabled ?
              <DisableButton setEnabled={setEnabled} /> :
              <button onClick={handleEnableClick} className="p-2 rounded-[10px] font-bold text-green-500 bg-green-100 dark:bg-green-50">
                Enable
              </button>
          }
        </div>
      }
    </Card>
  );
};

export default TwoFactor;

import { useAuthStore } from "store/useAuthStore";

const Verification = () => {
  const authStore = useAuthStore();

  return (
    <div>
      <h1 className="text-xl font-bold">Verification Status</h1>
      <div className={`mt-3 px-8 py-5 rounded-xl ${authStore.user?.verificationStatus === "VERIFIED" ? "bg-green-500" : authStore.user?.verificationStatus === "IN_PROGRESS" ? "bg-yellow-500" : "bg-red-500"}`}>
        <p className="text-lg text-center text-heuplus-50">
          {authStore.user?.verificationStatus.replace("_", " ")}
        </p>
      </div>
    </div>
  )
}

export default Verification;
import ChildIcon from "components/icons/ChildIcon";
import SpecializationCard from "./SpecializationCard";
import HeartPulseIcon from "components/icons/HeartPulseIcon";
import BrainIcon from "components/icons/BrainIcon";
import SkinIcon from "components/icons/SkinIcon";
import { useNavigate } from "react-router-dom";

export default function Specialization() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/patient/choose-physician");
  }
  const specializations = [
    { specialization: 'Pediatrics', icon: <ChildIcon /> },
    { specialization: 'Cardiology', icon: <HeartPulseIcon /> },
    { specialization: 'Psychology', icon: <BrainIcon /> },
    { specialization: 'Dermatology', icon: <SkinIcon /> }
  ];
  return (
    <div className="bg-heuplus-300 rounded-xl xsm:px-3 xsm:py-2 lg:px-8 lg:py-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl lg:text-3xl md:text-3xl sm:text-3xl xl:text-3xl">Specializations</h2>
        <button onClick={handleClick} className="text-md md:text-lg sm:text-lg xl:text-lg cursor-pointer">View All</button>
      </div>
      <div className="grid xsm:grid-cols-1 md:grid-cols-4 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4 xsm:px-3 xsm:py-2 md:px-8 md:py-5 sm:px-8 sm:py-5 lg:px-8 lg:py-5 xl:px-8 xl:py-5">
        {specializations.map((specialization) => (
          <SpecializationCard key={specialization.specialization} specialization={specialization.specialization} icon={specialization.icon} />
        ))}
      </div>
    </div>
  );
}

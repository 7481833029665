import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Configuration, TwoFactorControllerApi } from "rest-client-sdk";
import { useAuthStore } from "store/useAuthStore";
import { QRCodeSVG } from "qrcode.react";
import { notify, notifyError } from "toast";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";


const EnableTwoFactor: React.FC<{ secret: string, url: string, setShowEnable: (value: boolean) => void, setEnabled: (value: boolean) => void }> = ({ secret, url, setShowEnable, setEnabled }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const auth = useAuthStore();
  const [showQrCode, setShowQrCode] = useState(true);
  const [otp, setOtp] = useState(0);

  const api = new TwoFactorControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL,
  }));

  const verifyOtpRequest = async (token?: string) => {
    return api.verify2fa(
      {
        otpCode: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token ?? auth.token}`
        }
      }

    )
  }

  const handleSubmit = async () => {
    if (otp === -1 || otp.toString().length !== 6) {
      notify(t('Please fill all the fields')!);
      return;
    }

    let response = await verifyOtpRequest();
    if (response.data.statusCode === 200) {
      notify("Two-factor authentication enabled successfully");
      auth.updateUser({ requires2FA: true });
      setEnabled(true);
      setShowEnable(false);
      return;
    }
    if (response.data.statusCode !== 401) {
      notifyError("Invalid OTP code");
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate);
    response = await verifyOtpRequest(token);
    if (response.data.statusCode !== 200) {
      if (response.data.statusCode === 401) {
        notifyError("Invalid OTP code");
        return;
      }
      notifyError("Something went wrong");
      setShowEnable(false);
      return;
    }
    notify("Two-factor authentication enabled successfully");
    auth.updateUser({ requires2FA: true });
    setEnabled(true);
    setShowEnable(false);
  }

  return (
    <div className="flex flex-col justify-start mt-3 w-full">
      <h1 className="font-bold text-lg">Add authenticator app</h1>
      {
        showQrCode
        &&
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-md font-bold">Step 1: Scan This QR Code</p>
          <p className="opacity-70">Scan the following QR code with your authenticator app, such as Google Authenticator, Apple Passwords, etc.</p>
          <QRCodeSVG value={url} />
          <button className="text-heuplus-500" onClick={() => setShowQrCode(!showQrCode)}>
            Can't scan the QR code?
          </button>
        </div>
      }
      {
        !showQrCode &&
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-md font-bold">Step 1: Copy and paste the key</p>
          <p className="opacity-70">Paste the following key into your authenticator app, such as Google Authenticator, Apple Passwords, etc.</p>
          <p className="w-full text-center text-lg font-bold border bg-heuplus-100 border-heuplus-300 rounded-md p-2">{secret}</p>
          <button className="border border-heuplus-300 rounded-md p-2 w-full" onClick={() => navigator.clipboard.writeText(secret)}>Copy</button>
          <button className="text-heuplus-500" onClick={() => setShowQrCode(!showQrCode)}>
            Want to scan QR code?
          </button>
        </div>
      }
      <hr className="w-full my-6 border-gray-200" />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="text-md font-bold">Step 2: Enter the one-time code</p>
        <p className="opacity-70">Enter the 6-digit verification code generated by your authenticator app.</p>
        <div className="mt-7 mb-4 flex w-full items-center justify-center">
          <div className="mt-7 mb-4 flex w-full items-center justify-center">
            <PinInput
              type="number"
              onChange={(value) => setOtp(Number(value))}
              onComplete={(value) => setOtp(Number(value))}
              size="md"
            >
              <PinInputField w="40px" h="40px" padding={4} />
              <PinInputField w="40px" h="40px" padding={4} />
              <PinInputField w="40px" h="40px" padding={4} />
              <PinInputField w="40px" h="40px" padding={4} />
              <PinInputField w="40px" h="40px" padding={4} />
              <PinInputField w="40px" h="40px" padding={4} />
            </PinInput>
          </div>
        </div>
        <button onClick={handleSubmit} className="bg-heuplus-500 text-white rounded-md p-2 w-full">Enable Two-Factor Authentication</button>
      </div>
    </div>
  )
}

export default EnableTwoFactor;
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import React, { useState, useEffect } from "react";
import PageNotFound from "views/errors/pageNotFound";
import {ToastContainer} from "react-toastify";
import PatientLayout from "./layouts/patient";
import PhysicianLayout from "./layouts/physician";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {AvailableLanguages} from "./i18n/AvailableLanguages";
import SocketClientProvider from "./context/SocketClientProvider";

i18n.use(initReactI18next)
    .init({
      resources: AvailableLanguages as Record<string, { translation: Record<string, string> }>,
    })
    .then(() => console.log("i18n is initialized"));

const availableLanguagesList = Object.keys(AvailableLanguages);
let preferredLanguage = localStorage.getItem("heuplusLngPrf");

if (!preferredLanguage) {
  preferredLanguage = window.navigator.language.slice(0, 2);
}

if (availableLanguagesList.includes(preferredLanguage)) {
  localStorage.setItem("heuplusLngPrf", preferredLanguage);
} else {
  // Falling back to English
  localStorage.setItem("heuplusLngPrf", "en");
  preferredLanguage = "en";
}

i18n.changeLanguage(preferredLanguage)
    .then(() => console.log("Language is set to", preferredLanguage));

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState<any>({
        "--background-100": "#F3F5FA", // Grey 200: Light background color for high readability
        "--background-900": "#27374D", // Blue main 900: Dark background for contrast
        "--shadow-100": "rgba(112, 144, 176, 0.08)", // Retained for light shadows
        "--color-50": "#F4F7FD",       // Grey 100: Lightest neutral shade
        "--color-100": "#ECF0F5",      // Grey 300: Subtle light gray for backgrounds
        "--color-200": "#BEC7DB",      // Grey 400: Light blue-gray for components
        "--color-300": "#2754EB",      // Blue main 800: Primary action color
        "--color-400": "#4C5C7C",      // Grey 800: Secondary text or elements
        "--color-500": "#27374D",      // Blue main 900: Brand's main theme color
        "--color-600": "#74819B",      // Grey 700: Medium contrast elements
        "--color-700": "#2E4168",      // Grey 900: Strong accents or borders
        "--color-800": "#2754EB",      // Blue main 800: Reinforced brand accent color
        "--color-900": "#D21919"       // Red 900: Critical/danger elements
      }
  );
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);
  return (
    <div className="App">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
        <SocketClientProvider>{
          <Routes>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route
                path="admin/*"
                element={
                  <AdminLayout
                      setMini={setMini}
                      mini={mini}
                      theme={themeApp}
                      setTheme={setThemeApp}
                  />
                }
            />
            <Route
              path="physician/*"
              element={
                <PhysicianLayout
                  setMini={setMini}
                  mini={mini}
                  theme={themeApp}
                  setTheme={setThemeApp}
                />
              }
            />
            <Route
                path="patient/*"
                element={
                  <PatientLayout
                      setMini={setMini}
                      mini={mini}
                      theme={themeApp}
                      setTheme={setThemeApp}
                  />
                }
            />
            <Route path="/" element={<Navigate to="/auth/" replace />} />
            <Route
              path="*"
              element={<PageNotFound />}
            />
          </Routes>
        }</SocketClientProvider>
      <ToastContainer />
    </div>
  );
};

export default App;

const TwilioPlugin = (props: { formData: any, setFormData: (formData: any) => void }) => {
  const { formData, setFormData } = props;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 max-w-full">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Account SID
        </label>
        <input
          type="text"
          name="accountSid"
          value={formData.accountSid}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Account SID"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Auth Token
        </label>
        <input
          type="text"
          name="authToken"
          value={formData.authToken}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Auth Token"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Phone Number
        </label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Phone Number"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          From Mail Address
        </label>
        <input
          type="text"
          name="from"
          value={formData.from}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your From Mail Address"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          API Key
        </label>
        <input
          type="text"
          name="apiKey"
          value={formData.apiKey}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your API Key"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Template ID Contact Us
        </label>
        <input
          type="text"
          name="templateIdContactUs"
          value={formData.templateIdContactUs}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Template ID Contact Us"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Template ID Forgot Password
        </label>
        <input
          type="text"
          name="templateIdForgotPassword"
          value={formData.templateIdForgotPassword}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
          placeholder="Enter your Template ID Forgot Password"
        />
      </div>
    </div>
  )
};

export default TwilioPlugin;
